import { axiosInstance, endpoints } from "./axiosHandler";
import store from "../store/index.js";
import moment from "moment";

function formAPIUrlForID(id) {
  return endpoints.form + id + "/";
}

function createForm(endpoint, dict) {
  dict["source_device"] = "Desktop";
  return new Promise((resolve, reject) => {
    axiosInstance()
      .post(endpoints.form + endpoint, dict)
      .then(response => {
        resolve(response?.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}

function fetchForms(url, filters, cancelToken) {
  return new Promise((resolve, reject) => {
    axiosInstance()
      .get(url, { params: filters, cancelToken: cancelToken.token })
      .then(response => {
        resolve(response?.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}

function fetchPendingFormsCount() {
  return new Promise((resolve, reject) => {
    axiosInstance()
      .get(endpoints.form + "get_pending_count/")
      .then(response => {
        resolve(response?.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}

function fetchForm(id) {
  return new Promise((resolve, reject) => {
    axiosInstance()
      .get(formAPIUrlForID(id), null)
      .then(response => {
        resolve(response?.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}

function requestOffers(id, resellers) {
  return new Promise((resolve, reject) => {
    axiosInstance()
      .post(formAPIUrlForID(id) + "create-offers/", resellers)
      .then(response => {
        resolve(response?.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}

function orderPPSR(id, newRequest = false) {
  return axiosInstance().post(formAPIUrlForID(id) + "request-ppsr/", {
    new_request: newRequest
  });
}

function orderPPSRPDF(id) {
  return axiosInstance().get(formAPIUrlForID(id) + "request-ppsr-pdf/");
}

function markOfferAsFinal(offerUrl) {
  return new Promise((resolve, reject) => {
    axiosInstance()
      .patch(offerUrl + "make-final/", null)
      .then(response => {
        resolve(response?.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}

function markOfferAsTraded(offerUrl, tradedPurpose) {
  return new Promise((resolve, reject) => {
    axiosInstance()
      .patch(offerUrl + "make-winner/", { traded_purpose: tradedPurpose })
      .then(response => {
        resolve(response?.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}

function unmarkOfferAsTraded(form) {
  return new Promise((resolve, reject) => {
    axiosInstance()
      .patch(form.url + "unmark_as_traded/")
      .then(response => {
        resolve(response?.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}

function requestCustomOffer(offerData) {
  return new Promise((resolve, reject) => {
    axiosInstance()
      .post(endpoints.offer, offerData)
      .then(response => {
        resolve(response?.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}

function duplicateForm(form) {
  return new Promise((resolve, reject) => {
    axiosInstance()
      .post(form.url + "duplicate/", {
        is_ready_for_management: store.state.dealershipStore.currentDealer.is_manager
      })
      .then(response => {
        resolve(response?.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}

function markFormLost(form, reason, amount) {
  return new Promise((resolve, reject) => {
    axiosInstance()
      .post(form.url + "mark-lost/", {
        reasons_lost: reason,
        lost_amount: amount
      })
      .then(response => {
        resolve(response?.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}

function unmarkFormLost(form) {
  return new Promise((resolve, reject) => {
    axiosInstance()
      .patch(form.url, { is_lost: false })
      .then(response => {
        resolve(response?.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}

function markFormArchived(form, archived) {
  return new Promise((resolve, reject) => {
    axiosInstance()
      .patch(form.url, { is_archived: archived })
      .then(response => {
        resolve(response?.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}

function markFormReadyForManagement(form) {
  return new Promise((resolve, reject) => {
    axiosInstance()
      .patch(form.url, { is_ready_for_management: true })
      .then(response => {
        resolve(response?.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}

function markFormFavourite(form, favourite) {
  return new Promise((resolve, reject) => {
    axiosInstance()
      .patch(form.url, { is_favourite: favourite })
      .then(response => {
        resolve(response?.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}

function markFormReadyForTrade(form, eta) {
  var patch_dict;
  if (eta) {
    patch_dict = { is_ready_for_traded: true, traded_eta: moment(eta).format("yyyy-MM-DD") };
  } else {
    patch_dict = { is_ready_for_traded: true };
  }
  return new Promise((resolve, reject) => {
    axiosInstance()
      .patch(form.url, patch_dict)
      .then(response => {
        resolve(response?.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}

function markTrashed(form, trash) {
  return new Promise((resolve, reject) => {
    axiosInstance()
      .patch(form.url, { is_trash: trash })
      .then(response => {
        resolve(response?.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}

function checkOnlinePresence(form) {
  return new Promise((resolve, reject) => {
    axiosInstance()
      .get(form.url + "request-autograb-is-on-internet/")
      .then(response => {
        resolve(response?.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}

function fetchFactoryVehicleOptions(form) {
  return new Promise((resolve, reject) => {
    axiosInstance()
      .get(form.url + "request-autograb-factory-vehicle-options/")
      .then(response => {
        resolve(response?.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}

function alertWinner(form) {
  return new Promise((resolve, reject) => {
    axiosInstance()
      .post(form.url + "alert-winner/")
      .then(response => {
        resolve(response?.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}

function markFormArrived(form) {
  return new Promise((resolve, reject) => {
    axiosInstance()
      .patch(form.url + "arrived/", {
        traded_arrived: true,
        traded_eta: moment(new Date()).format("yyyy-MM-DD")
      })
      .then(response => {
        resolve(response?.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}

function transferForms(forms, dealership) {
  return new Promise((resolve, reject) => {
    axiosInstance()
      .post(endpoints.form + "transfer/", {
        forms: forms,
        dealership: dealership
      })
      .then(response => {
        resolve(response?.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}

function archiveAllForms(dealershipID) {
  return new Promise((resolve, reject) => {
    axiosInstance()
      .post(endpoints.dealership + dealershipID + "/archive-all-forms/", null)
      .then(response => {
        resolve(response?.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}

function startTender(timeDict) {
  return new Promise((resolve, reject) => {
    axiosInstance()
      .post(endpoints.form + "create-tender/", timeDict)
      .then(response => {
        resolve(response?.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}

function getPDF(formID) {
  return new Promise((resolve, reject) => {
    axiosInstance()
      .get(endpoints.form + formID + "/request-pdf/", {
        responseType: "arraybuffer"
      })
      .then(response => {
        resolve(response);
      })
      .catch(error => {
        reject(error);
      });
  });
}

function downloadCSV(url, filters) {
  return new Promise((resolve, reject) => {
    axiosInstance()
      .get(url, {
        params: filters,
        responseType: "blob"
      })
      .then(response => {
        resolve(response);
      })
      .catch(error => {
        reject(error);
      });
  });
}

function updateFormTPCFields(form, retailCost, yardFee, onRoadCosts, margin) {
  return new Promise((resolve, reject) => {
    axiosInstance()
      .patch(form.url, {
        tpc_retail_price: retailCost,
        tpc_yard_fee: yardFee,
        tpc_margin: margin,
        tpc_on_road_costs: onRoadCosts
      })
      .then(response => {
        resolve(response?.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}

function recalibrateFormData(form, rego, state) {
  return new Promise((resolve, reject) => {
    axiosInstance()
      .patch(form.url + "update-from-rego/", {
        car_registration_number: rego,
        car_registration_state: state
      })
      .then(response => {
        resolve(response?.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}

function updateForm(form) {
  let dataDict = {
    customer_name: form.customer_name,
    customer_address: form.customer_address,
    customer_postcode: form.customer_postcode,
    customer_home_phone: form.customer_home_phone,
    customer_work_phone: form.customer_work_phone,
    customer_email: form.customer_email,
    registration_entity: form.registration_entity,
    car_make: form.car_make,
    car_model: form.car_model,
    car_model_year: form.car_model_year,
    car_model_code: form.car_model_code,
    redbook_code: form.redbook_code,
    car_line: form.car_line,
    car_series: form.car_series,
    car_body: form.car_body,
    car_fuel: form.car_fuel,
    car_mileage: form.car_mileage,
    car_transmission: form.car_transmission,
    car_compliance_date: form.car_compliance_date,
    car_build_date: form.car_build_date,
    car_year_of_manufacture: form.car_year_of_manufacture,
    car_colour: form.car_colour,
    car_trim: form.car_trim,
    car_front_tread: form.car_front_tread,
    car_rear_tread: form.car_rear_tread,
    is_import: form.is_import,
    ruc_expiry: form.ruc_expiry,
    car_registration_number: form.car_registration_number,
    car_registration_expiry: form.car_registration_expiry,
    car_registration_state: form.car_registration_state,
    car_chassis_number: form.car_chassis_number,
    car_engine_number: form.car_engine_number,
    car_seat_count: form.car_seat_count,
    car_carpets: form.car_carpets,
    car_roof_lining: form.car_roof_lining,
    car_seat_quality: form.car_seat_quality,
    car_door_trims: form.car_door_trims,
    car_fascia: form.car_fascia,
    car_boot: form.car_boot,
    car_wheel_size: form.car_wheel_size,
    car_cylinders: form.car_cylinders,
    is_currently_encumbered: form.is_currently_encumbered,
    service_history_quality: form.service_history_quality,
    service_history_comments: form.service_history_comments,
    car_spare_keys: form.car_spare_keys,
    imported_from: form.imported_from,
    wof_expiry: form.wof_expiry,
    approx_payout: form.approx_payout,
    monthly_repayment: form.monthly_repayment,
    new_car_interested_in: form.new_car_interested_in,
    target_monthly_payment_on_new_purchase: form.target_monthly_payment_on_new_purchase,
    is_finance_requested: form.is_finance_requested,
    financier: form.financier,
    dealership_comments: form.dealership_comments,
    car_comments: form.car_comments,
    traded_purpose: form.traded_purpose,
    traded_eta: form.traded_eta,
    sales_value: form.sales_value,
    retail_value: form.retail_value,
    reserve_price: form.reserve_price,
    car_stock_number: form.car_stock_number,
    offer_valid_date: form.offer_valid_date,
    tpc_retail_price: form.tpc_retail_price,
    tpc_yard_fee: form.tpc_yard_fee,
    tpc_margin: form.tpc_margin,
    tpc_on_road_costs: form.tpc_on_road_costs,
    department: typeof form.department == "object" && form.department != null ? form.department.url : form.department,
    starting_dealer:
      typeof form.starting_dealer == "object" && form.starting_dealer != null
        ? form.starting_dealer.url
        : form.starting_dealer
  };

  return new Promise((resolve, reject) => {
    axiosInstance()
      .patch(form.url, dataDict)
      .then(response => {
        resolve(response?.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}

function updateFormWithDict(form, dict) {
  return new Promise((resolve, reject) => {
    axiosInstance()
      .patch(form.url, dict)
      .then(response => {
        resolve(response?.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}

function addFormToTender(form, tenderID) {
  return new Promise((resolve, reject) => {
    axiosInstance()
      .patch(form.url, { tender: tenderID })
      .then(response => {
        resolve(response?.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}

function removeFormFromTender(form) {
  return new Promise((resolve, reject) => {
    axiosInstance()
      .patch(form.url, { tender: null })
      .then(response => {
        resolve(response?.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}

function requestAutoGrabVehicles(form) {
  return new Promise((resolve, reject) => {
    axiosInstance()
      .get(form.url + "request-autograb-id/")
      .then(response => {
        resolve(response?.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}

function requestAutoGrabFetchData(form, vehicleID) {
  return new Promise((resolve, reject) => {
    axiosInstance()
      .get(form.url + "request-autograb-price/", {
        params: {
          vehicle_id: vehicleID
        }
      })
      .then(response => {
        resolve(response?.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}

function requestAutoGrabFetchDataUsingRego(form) {
  return new Promise((resolve, reject) => {
    axiosInstance()
      .get(form.url + "request-autograb-price-using-rego/")
      .then(response => {
        resolve(response?.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}

function requestAutoGrabLiveMarketFromForm(form_id, age, include_adjacent_years) {
  return new Promise((resolve, reject) => {
    axiosInstance()
      .get(formAPIUrlForID(form_id) + "request-autograb-live-market/", {
        params: {
          age: age,
          include_adjacent_years: include_adjacent_years
        }
      })
      .then(response => {
        resolve(response?.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}

function requestAutoGrabVehicleDetailsFromForm(form_id) {
  return new Promise((resolve, reject) => {
    axiosInstance()
      .get(formAPIUrlForID(form_id) + "request-vehicle-info/")
      .then(response => {
        resolve(response?.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}

function requestAutoGrabVehicleReportForForm(form_id) {
  return new Promise((resolve, reject) => {
    axiosInstance()
      .get(endpoints.form + form_id + "/request-autograb-report/", {
        params: {
          perform_async: true
        }
      })
      .then(response => {
        resolve(response?.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}

function uploadGeorgeReport(reportDict) {
  return new Promise((resolve, reject) => {
    let formData = new FormData();
    for (const [key, value] of Object.entries(reportDict)) {
      formData.append(key, value);
    }

    axiosInstance()
      .post(endpoints.form + "upload-george-csv/", formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })
      .then(response => {
        resolve(response?.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}

function uploadGeneralPhoto(form, photo) {
  let formData = new FormData();
  formData.append("photo", photo);

  return new Promise((resolve, reject) => {
    axiosInstance()
      .post(form.url + "general-photos/", formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })
      .then(response => {
        resolve(response?.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}

export {
  formAPIUrlForID,
  createForm,
  fetchForms,
  fetchPendingFormsCount,
  fetchForm,
  requestOffers,
  requestCustomOffer,
  markOfferAsFinal,
  markOfferAsTraded,
  alertWinner,
  unmarkOfferAsTraded,
  duplicateForm,
  markFormLost,
  unmarkFormLost,
  markFormArchived,
  markFormArrived,
  markFormReadyForManagement,
  markFormFavourite,
  markTrashed,
  transferForms,
  archiveAllForms,
  startTender,
  orderPPSR,
  orderPPSRPDF,
  updateFormTPCFields,
  updateForm,
  updateFormWithDict,
  getPDF,
  recalibrateFormData,
  downloadCSV,
  addFormToTender,
  markFormReadyForTrade,
  removeFormFromTender,
  requestAutoGrabFetchData,
  requestAutoGrabFetchDataUsingRego,
  requestAutoGrabLiveMarketFromForm,
  requestAutoGrabVehicles,
  requestAutoGrabVehicleDetailsFromForm,
  requestAutoGrabVehicleReportForForm,
  uploadGeorgeReport,
  checkOnlinePresence,
  fetchFactoryVehicleOptions,
  uploadGeneralPhoto
};
